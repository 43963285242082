@font-face {
font-family: 'kievit';
src: url(/_next/static/media/f5c86e63f901dd25-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/ffb2b70bcbf26538-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/53fbce4093f8a548-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/8e0555cf9d9e753f-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/cc42efe8275ae3ea-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/fb94d19f2b5e83db-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/ced0323f12c78b9f-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/c1119d2a8ba2992f-s.p.otf) format('opentype');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/0b0e1ede84d5b54b-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/481ac75ee71063f5-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/b0d168f2af317e76-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/a4d73ef1f267e082-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/7bb3166bd7d3f009-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/d2c59e02e4f6da5f-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/db0f484e2a38ffab-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/4cb33b5a0bf34af0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/8e0b4185fbe81733-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: 'kievit';
src: url(/_next/static/media/0326cfcdd52fa38c-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: italic;
}@font-face {font-family: 'kievit Fallback';src: local("Arial");ascent-override: 101.07%;descent-override: 29.74%;line-gap-override: 0.00%;size-adjust: 92.80%
}.__className_ed8cee {font-family: 'kievit', 'kievit Fallback', Helvetica Neue, Arial, sans-serif
}.__variable_ed8cee {--zbx-font-kievit: 'kievit', 'kievit Fallback', Helvetica Neue, Arial, sans-serif
}

@font-face {
font-family: 'lato';
src: url(/_next/static/media/ffcc1d0af9313088-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'lato';
src: url(/_next/static/media/2fe2df8844fda0a1-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'lato';
src: url(/_next/static/media/0599d25886198877-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}@font-face {font-family: 'lato Fallback';src: local("Arial");ascent-override: 99.53%;descent-override: 21.48%;line-gap-override: 0.00%;size-adjust: 99.17%
}.__className_2c261c {font-family: 'lato', 'lato Fallback', Helvetica Neue, Arial, sans-serif
}.__variable_2c261c {--zbx-font-lato: 'lato', 'lato Fallback', Helvetica Neue, Arial, sans-serif
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/1463456c6166c4bc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/0048e01d9ad2cf3b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/9222470ba43ec68e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/d380295f69f806ec-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'roboto Fallback';src: local("Arial");ascent-override: 92.43%;descent-override: 24.32%;line-gap-override: 0.00%;size-adjust: 100.37%
}.__className_c26208 {font-family: 'roboto', 'roboto Fallback', Helvetica Neue, Arial, sans-serif
}.__variable_c26208 {--zbx-font-roboto: 'roboto', 'roboto Fallback', Helvetica Neue, Arial, sans-serif
}

